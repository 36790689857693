.search input{
    width: 75%;
    font-family: 'Manrope',sans-serif;

}

.mob-search-title{
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
}

.mob-search-main{
    padding: 16px;
    background-color:#F5F5F5 ;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 80vh;
}

.mob-search-main-block{
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    line-height: 0;
    overflow:hidden
}

span{
    text-align: center;
    line-height: 1;

}
.storimage{
    overflow:hidden;
    border-radius: 50%;
    border: 3px #3153CA solid;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.storimage img{
    width: 80px;
}

.mob-search-main-block span{
    font-size: 10px;
    font-weight: 600;
}

.mob-search-main-block img{
    /*border-radius: 50%;*/
height: 100%;
    width: auto;
/*    width: 100%;*/
}
.mob-search-main-res{
    padding-bottom: 100px;
}
.mob-search-main-res-block{
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 18px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.mob-search-main-res-block div{
    display: flex;
    align-items: center;
    gap: 12px;
}

.mob-search-main-res-block div img{
    border-radius: 8px;
    width: 80px;

}

.mob-search-main-res-block div h4{
    font-size: 14px;
    font-weight: 600;
}

.search{
    /*margin-top: 20px;*/
    display: flex;
    align-items: center;
}

.search1{
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
    /*position: relative;*/
}

.search img{
    position: absolute;
    left: 12px;
    /*z-index: 5;*/
    width: 16px;
}

.search-input{
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #F4F4F4;
    color: #18181B;
    padding: 8px 12px 8px 40px;
    font-family: Manrope;
}