button{
    padding: 6px 10px;
    border: .57px solid #3153CA;
    border-radius: 5.71px;
    font-size: 16px;
    font-weight: 500;
    word-wrap: break-word;
    background-color: #fff;
    cursor: pointer;
}

@media screen and (min-width: 960px){
    button{
        padding: 6px 20px;
        border-radius: 10px;
        font-size: 16px;
        border: 1px solid #3153CA;
    }
}