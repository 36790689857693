.certification{
    background-color: #f5f5f5;
    padding: 24px 16px;
    border-radius: 24px 24px 0 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    gap: 12px;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}

.certification h4{
    font-size:12px;
    font-weight: 700;
}

.certification-header{
    display: flex;
    align-items: center;
    gap: 4px;
}

.certification p{
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
}

.certification h3{
    font-size: 32px;
    font-weight: 700;
}

.certification .info{
    width: 100%;
}

.certification .attention{
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-bottom: 20px;
    font-size: 10px;
}

.last {
    padding-bottom: 15px;
    border-bottom: 1px solid #D9D9D9;
}
.activate{
    font-weight: 550;
    text-decoration: none;
    background: white;
    color: #3153CA;
    /*margin-bottom: 80px;*/
    font-size: 150%;
    padding: 15px;
    border: black solid 2px;
    border-radius: 15px;
}
.activate:hover{
    background: #91A3E2;
}
@media screen and (min-width: 970px){
    .certification{
        padding: 24px;
        gap: 20px;
        top: 15%;
        left: 30%;
        width: auto;
    }
    .certification h4{
        font-size: 20px;
    }
    .certification p{
        font-size: 18px;
        font-weight: 500;
        opacity: 0.5;
    }
    .certification .attention{
        font-size: 14px;
    }
    .certification .ifo h5{
        font-size: 20px;
    }
}