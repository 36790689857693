.favorites .main-content{
    background-color: #F5F5F5;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    height: 85vh;
    padding-bottom: 98px;
}

@media screen and (min-width: 970px){
    .favorites .main-content{
        padding: 32px 148px;
        gap: 24px;
        height: 100%;
    }
}