.selected-price {
    background-color: #C1CBEF;
}
.error{
    color:red;
}
.cerf-modal{
    padding-bottom: 80px;
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}

.cerf-modal-content{
    background-color: #fff;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    border-radius: 18px 18px 0 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /*align-items: center;*/
}

.cerf-modal-content .h5{
    font-size: 14px;
    font-weight: 600;
}

.cerf-modal-content .price span{
    padding: 0 12px;
    font-size: 14px;
}
.certificate{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.certificate span{
    font-size: 10px;
}

.cerf-modal .certificate-button{
    width: 100%;
}