.main-cont{
    width: 100%;
    margin: 50px 0;
    gap: 24px;
    display: flex;
    flex-direction: column;
}
.center{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.text1{
    font-size: 15px;
    width: 80%;
    display: flex;
    justify-content: space-around;
    padding: 5%;
    margin: 0 5%;
    border-radius: 16px;
    background: #F1F4FF;
}
.row-amount{
    font-size: 14px;
    margin: 0 5%;
    display: flex;
    gap: 12px
}
.margin5{
    margin: 0 5%;
}
.text-14{
    font-size: 14px
}
.h38{
    height: 38px;
}
.w-100{
    font-size: 14px;
    width: 100%;
}
.w-90{
    width: 100%;
}

.amount{
    font-weight: bold;
    font-size: 14px;
    color: #3153CA;
    width: 110px;
    height: 38px;
    padding: 6px 16px 6px 16px;
    border-radius: 10px;
    border: 1px;
    gap: 10px;
    border: #3153CA33 1px solid;
}
.selected {
    background-color: #C1CBEF;
}
.row-input{
    margin: 0 5%;
    display: flex;
    gap: calc(5% + 20px);
}
form{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
input{
    border-width: 0;
    height: 48px;
    border-radius: 10px;
    background: #F7F7F7;
    padding-left: 20px;
}

.row{
    font-size: 18px;
    text-align: center;
    width: 90%;
    margin: 0 5%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.row input{
    width: 94%;
}
.submit{
    margin: 0 5%;
    color: white;
    font-size: 14px;
    /*width: Fi;*/
    height: 48px;
    padding: 12px 16px 12px 16px;
    border-radius: 10px;
    border: 0 solid;
    gap: 10px;
    background: #F44027;

}