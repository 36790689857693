.search{
    display: flex;
    align-items: center;
    position: relative;
}

.search1{
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
    /*position: relative;*/
}

.search img{
    position: absolute;
    left: 12px;
    width: 16px;
}

.search-input{
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #F4F4F4;
    color: #18181B;
    padding: 8px 12px 8px 40px;
    font-family: 'Manrope',sans-serif;

}

.dropdown{
    position: absolute;
    color: #52525B;
    width: 300px;
    background-color: white;
    border-radius: 5px;
    margin: 15px 0;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.card_search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: black;
    background: #E7E7E7;
    padding: 10px;
    /*border: 1px solid black;*/
    border-radius: 10px;
}
.title{
    width: 50%;
    height: 40px;
    font-size: 14px;
    font-weight: bolder;
    align-items: center;
    word-break: break-word;
    align-content: center;
    word-spacing: 2px;
    line-height: 1.5;
}
.card_search:hover{
    background: #91A3E2;
}