.banner-desk{
    display: none;
}

.banner-mob{
    padding: 0;
    width: 100%;
    background: #D5D7FF;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
.banner-mob button {
    color: white;
    font-size: 14px;
    margin-bottom: 20px;
    width: 85%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border-radius: 10px;
    border: 0 solid;
    gap: 10px;
    background: #F44027;
}
.banner-mob form{
    align-items: center;
}
.banner-mob input{
    width: 80%;
}
.banner-mob h2{
    font-size: 18px;
}

.banner-mob p{
    font-size: 14px;
}

.banner-left{
    padding-left: 60px;
    padding-right: 20px;
    box-sizing: border-box;
}

.banner-left p{
    margin-top: 10px;
    margin-bottom: 36px;
    max-width: 500px;
}

.banner-left-frame{
    display: flex;
    align-items: center;
    gap: 16px;
}

.banner-right img{
    width: 100%;
    border-radius: 0 24px 24px 0;
}

@media screen and (min-width: 970px){
    .banner-desk{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1300px;
        background-color: #fff;
        border-radius: 24px;
    }

    
    .banner-mob{
        display: none;
    }
}