.conditions{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: absolute;
    background-color: #fff;
}
.conditions-main{
    font-family: 'Manrope',sans-serif;

    width: 100%;
    height: 95%;
    overflow-y: auto;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.conditions-h2{
    font-family: 'Manrope',sans-serif;
    color: #090914;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.conditions p{
    color: #52525B;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
}

.conditions .link-btn{
    background-color: transparent;
    border: none;
    color: #3153CA;
    font-size: 12px;
    font-weight: 600;
}

.conditions .link-btn + button{
    padding: 10px 84px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #3153CA;
    color: #fff;
    font-size: 14px;
}

@media screen and (min-width: 970px){
    .conditions{
        background-color: #F8FAFC;
    }
    .conditions-main{
        width: 40%;
        height: 555px;
        padding: 24px;
        gap: 36px;
        align-items: center;

    }
    
    .conditions-h2{
        font-size: 36px;
    }
    
    .conditions p{
        font-size: 16px;
    }
    
    .conditions .link-btn{
        font-size: 16px;
    }
    .conditions .link-btn + button{
        padding: 10px 104px;
        font-size: 16px;
    }

}