
.form {
    padding: 24px;
    border-radius: 24px 24px 0 0;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    width: 100%;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
}

.form h3{
    color: #18181B;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
}

.form-control {
    width: 100%; 
    height: 26px;
    margin-right: 0.5rem;
    text-align: center;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #E7E7E7;
    padding: 12px 16px;
    font-size: 1.25rem;
}

.form-control:last-child {
    margin-right: 0;
  }

.d-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form .code-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}

.form a{
    color: #3153CA;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
}

@media screen and (min-width: 970px){
    .form {
        position: absolute;
        top: 30%;
        left: 30%;
        bottom: auto;
        border-radius: 24px;
        width: 40%;
    }
}