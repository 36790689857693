.registration p{
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
}

.registration a{
    text-decoration: none;
    color: #3153CA;
}

@media screen and (min-width: 970px) {
    .registration p{
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
    
}
    