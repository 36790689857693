/* FullScreenImage.css */
.full-screen-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.full-screen-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}
