.banner2{
    padding: 16px; 
    background: #3153CA; 
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}

.banner2-header{
    display: flex;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.banner2-header img{
    width: 32px;
    height: 32px;
}

.banner2-button{
    color: #3153CA;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    padding: 8px 20px;
}

@media screen and (min-width: 960px){
    .banner2{
        padding: 24px 40px; 
        flex-direction: row;
        justify-content: space-between;
    }
    .banner2-contacts{
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .banner2-contacts img{
        padding: 7.5px;
        background-color: #fff;
        border-radius: 50%;
    }
    .banner2-header{
        gap: 24px;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
    }

    .banner2-header img{
        width: 44px;
        height: 44px;
    }

    .banner2-button{
        color: #3153CA;
        font-size: 18px;
    }
}