.profile{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.profile-header, .profile-header-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.profile-header-link img{
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
}

.profile-cert{
    padding: 22px;
    border: 1px solid #3153CA;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    cursor: pointer;
}

/*.profile-cert .h3{*/
/*    font-weight: 550;*/
/*    font-size: 14px;*/
/*}*/

.profile-cert-img{
    display: flex;
    align-items: center;
    gap: 4px;
}
.profile-cert-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-cert h1{
    color: #3153CA;
    font-size: 36px;
}

.profile .p{
    font-size: 12px;
    line-height: 26px;
}

@media screen and (min-width: 970px){
    .profile-main{
        flex-direction: row;
        gap: 24px;
    }
    .shadow .h2{
        line-height: 34px;
        font-size: 24px;
    }
    .profile .p, .profile span, .profile h5{
        font-size: 20px;

    }
    .profile .shadow{
        padding: 40px;
        gap: 32px;
    }
    .profile-header-link{
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .profile-cert-info{
        flex-direction: column;
        gap: 24px;
        align-items: start;
    }
    .profile-cert{
        gap: 12px;
    }
    .profile-cert h1{
        font-size: 30px;
    }
}