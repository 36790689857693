.menu-carousel{
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.carousel-content{
    position: fixed;

    /*background-color: #fff;*/
    padding: 24px;
    width: 80%;
    height: 95%;
    box-sizing: border-box;
    /*position: absolute;*/
    left: 10%;
    border-radius: 18px 18px 0 0;
    display: flex;
    flex-direction: column;
    /*gap: 12px;*/
}
.carousel .thumbs {
    display: none;
}
.carousel-status{
    display: none;
}
.close_div img{
    position: absolute;
    width: 4%;
    left: 100%;
    z-index: 1;
}
.image{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    align-content: center;
}
.image1{
    position: relative;
    width: 470px;
}
@media screen and (max-width: 970px) {
    .carousel-content {
        width: 100%;
        top: 5%;
        left: 0;
    }
    .close_div img{
        left: 95%;
    }
}