.header-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 24px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
}

.tags-card{
    display: flex;
    align-items: center;
    gap: 8px;
}
.tag-item{
    padding: 7px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #EDB92E;
    color: #18181B;
    height: 20px;
    border-radius: 7px;
    font-size: 16px;
}
.header-card .card-button{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background-color: rgba(49.12, 82.88, 201.88, 0.10);
    color: #3153CA; 
    font-size: 18px;
}

.header-card .card-button img{
    width: 36px;
    height: 36px;
}

