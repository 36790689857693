.registration{
    width: 100%;
    padding: 24px 16px;
    background-color: #fff;
    border-radius: 24px 24px 0 0;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    /* z-index: -1; */
}

.registration-form{
    font-size: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.registration-form h2{
    color: #090914;
    font-size: 18px;
    font-weight: 800;
}

.registration-form input{
    color: #52525B;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    padding: 8px 16px;
    background: white; 
    border-radius: 10px; 
    border: 0.50px #3153CA solid;
    width: 100%;
    outline: #3153CA;
    box-sizing: border-box;
    text-align: center;
}

.registration-inputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    width: 100%;
}

.registration-inputs input{
    text-align: left;
}

.registration-checkbox{
    display: flex;
    align-items: center;
    gap: 6px;
}

.registration-checkbox label{
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
}

.registration-checkbox a{
    text-decoration: none;
    color: #3153CA;
    cursor: pointer;
}

.registration-checkbox input{
    margin: 0;
    width: auto;
}

.registration-form>a{
    text-decoration: none;
    width: 100%;
}

.registration-form button{
    padding: 12px 84px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #3153CA;
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
}

@media screen and (min-width: 970px) {
    .registration-inputs{
        flex-direction: column;
    }

    .registration-form h2{
        font-size: 24px;
    }

    .registration-inputs input{
        text-align: center;
    }
    .registration-form input{
        font-size: 16px;
        padding: 12px 16px;
    }
    .registration-checkbox{
        align-items: center;
        justify-content: flex-start;
        gap: 9px;
        width: 100%;
    }
}
@media screen and (max-width:970px) {
    .registration{
        bottom: 8%;
    }
}