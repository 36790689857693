p, h1, h2, h3, h4, h5, span, button, a, input, li {
    font-family: 'Manrope',sans-serif;
}
*{
    font-family: 'Manrope',sans-serif;

}
span{
    font-weight: 500;
    line-height: 24px;
}

h1{
    color: #18181B;
    font-size: 32px;
    font-weight: 700;
    word-wrap: break-word
}

h2{
    color: #18181B;
    font-weight: 700;
    line-height: 44px;
    word-wrap: break-word
}

h3{
    color: #18181B;
    font-weight: 600;
    word-wrap: break-word;
}

P{
    color: #18181B;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    word-wrap: break-word
}