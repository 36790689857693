@import url('https://fonts.google.com/specimen/Manrope');


@import url('./style/reset.css');
@import url('./style/buttons.css');
@import url('./style/base.css');

@font-face {
    font-family: 'Manrope';
    src: local('manrope'),
    url('./fonts/manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: bold;
}