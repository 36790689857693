.card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 12px 0 12px;
    width: 100%;
    gap: 12px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    /*position: relative;*/
}


.card-header{
    color: #fff;
    background-color: #3153CA;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 16px;
    border: 2px solid #fff;
    font-weight: 600;
    margin-bottom: -45px;
    margin-left: 10px;
    z-index: 2  ;
    box-sizing: border-box;
}

.card-img{
    position: relative;
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    border-radius: 12px;
}
.card-img img{
    width: 100%;
    border-radius: 12px;
    max-height: 100%;
}

.tags{
    position: absolute;
    /*margin-top: 30px;*/
    bottom: 2%;
    left: 3%;
    /*margin-left: 10px;*/
    display: flex;
    gap: 4px;
}

.tag{
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #EDB92E;
    color: #18181B;
    /*font-weight: 600;*/
    height: 20px;
    /*word-wrap: break-word;*/
    border-radius: 10px;
}
.tag p{
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 12px;
    font-weight: 550;
    width: max-content;
}

.card-body{
    height: max-content;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;

}

.card-body img{
    border-radius: 8px;
}

.card-body h1{
    font-size: 28px;
    margin-bottom: 10px;
}

.card-text{
    height: 60px;
    font-size: 10px;
    text-decoration: none;
    line-height: 1;
    color: #18181B;
}

.location{
    padding: 8px 12px;
    background-color: #F0F0F0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
}

.location span{
    font-size: 14px;
    color: #18181B;
    font-weight: 400;
}

.card-footer .card-button:last-child{
    display: none   
}

.card-button:first-child{
    position: absolute;
    top: 20px;
    right: 26px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 6.25px;
}

.card-footer img{
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media screen and (min-width: 970px){
    .card{
        width: 32%;
        padding: 20px 18px ;

    }
    .card-footer img{
        width: 26px;
    }
    .card-footer{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;
    }
    
    .card-footer .card-button:first-child{
        padding: 11px 19px;
        position: static;
    }
    
    .card-footer .card-button:last-child{
        display: block;
        padding:7px 0;
        box-sizing: border-box;
        background-color: #3153CA;
        color: #fff;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        text-decoration: none;
    }
    .card-header + img{
        max-width: 100%;
        border-radius: 12px;
    }
    .card-body h3{
        font-size: 18px;
    }
    span, .tag{
        font-size: 150%;
    }
    .card-button.liked {
        background: rgba(49.12, 82.88, 201.88, 0.10);
    }
    .card-text{
        font-size: 14px;
    }
}

@media screen and (max-width: 970px){
    .card-img{
        height: 135px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        border-radius: 12px;
    }
}