.errors{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}
.input{
    color: #52525B;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    padding: 8px 16px;
    background: white; 
    border-radius: 10px; 
    border: 0.50px #3153CA solid;
    width: 100%;
    outline: #3153CA;
    box-sizing: border-box;
    text-align: center;
}

.error1{
    outline: #FF3D00;
    border: 0.50px #FF3D00 solid;
    color: #FF3D00;
}

.error2::placeholder, .error{
    color: #FF3D00;
}

.error{
    font-size: 12px;
    font-weight: 500;
    padding-left: 16px;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #3153CA;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.error+label::before {
    border: 1px solid #FF3D00;
}

.custom-checkbox:checked+label::before {
    border-color: #3153CA;
    background-color: transparent;
    background-image: url("../../assets/Frame3.svg");
}
