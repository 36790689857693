.profile-main{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.profile .shadow{
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;
}

.shadow .h2{
    line-height: 25px;
}

.profile-edit{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.profile-edit span{
    color: #3153CA;
    line-height: 1;
}

.shadow .info .profile-input{
    background-color: transparent;
    color: #18181B;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    width: auto;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right
}

.shadow .info{
    border-bottom: 1px solid rgba(217, 217, 217, 0.50);
    padding: 8px 0;
}

.shadow .info:last-child{
    border-bottom: none;
}

@media screen and (min-width: 970px) {
    .shadow .info .profile-input{
        font-size: 20px;
    }
}
    